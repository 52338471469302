import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CodeHighlighter } from '@abyss/web/ui/CodeHighlighter';
import { styled } from '@abyss/web/tools/styled';
import { Tabs } from '@abyss/web/ui/Tabs';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { useParams } from '@abyss/ui/router/hooks/useParams';

const BodyContainer = styled('div', {
  marginTop: 24,
});

export const Body = ({ body, currentApi }) => {
  const { service } = useParams();
  const formattedCode = JSON.stringify(body, null, 2);
  const featureToggles = useLagoon('configs');
  const serviceToggle = featureToggles('SERVICE_TOGGLE')?.value;
  const serviceDescriptionToggle = featureToggles('SERVICE_DESCRIPTION_TOGGLE')
    ?.value;

  const asteriskText = key => {
    return (
      <span>
        {key}
        <span style={{ color: '#C40000' }}> *</span>
      </span>
    );
  };

  const col = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
  ];
  if (serviceDescriptionToggle?.includes(service)) {
    col.push({ Header: 'Description', accessor: 'description' });
  }

  const columns = React.useMemo(() => col, []);
  const formattedSchema = data => {
    if (!data) return [];
    const schemaData = Object.keys(data).map(key => {
      const value = data[key];
      let { type } = value;
      if (data[key].type === 'object') {
        type = 'object';
        const subRows = formattedSchema(value.properties);
        return {
          field: data[key].required ? asteriskText(key) : key,
          type,
          subRows,
          description: value?.description?.substring(0, 200),
        };
      }
      if (data[key].type === 'array') {
        type = 'array';
        const subRows = formattedSchema(value?.items?.properties);
        return {
          field: data[key].required ? asteriskText(key) : key,
          type,
          subRows,
          description: value?.description?.substring(0, 200),
        };
      }
      return {
        field: data[key].required ? asteriskText(key) : key,
        type,
        description: value?.description?.substring(0, 200),
      };
    });
    return schemaData;
  };
  const data = useMemo(() => [...formattedSchema(currentApi)], [currentApi]);

  const dataTableProps = useDataTable({
    initialData: data,
    initialColumns: columns,
    showExpansion: true,
  });

  useEffect(() => {
    dataTableProps.setData(formattedSchema(currentApi), true);
  }, [currentApi]);

  return (
    <BodyContainer>
      {serviceToggle?.includes(service) ? (
        <Tabs title="Arrow Variant" variant="arrow">
          <Tabs.Tab label="Schema">
            <DataTable tableState={dataTableProps} />
          </Tabs.Tab>
          <Tabs.Tab label="Sample">
            <CodeHighlighter
              language="js"
              code={formattedCode}
              maxHeight="450px"
            />
          </Tabs.Tab>
        </Tabs>
      ) : (
        <CodeHighlighter language="js" code={formattedCode} maxHeight="450px" />
      )}
    </BodyContainer>
  );
};

Body.propTypes = {
  body: PropTypes.shape({}),
  currentApi: PropTypes.shape({}).isRequired,
};

Body.defaultProps = {
  body: null,
};
